import { graphql } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'

import type { JobBySlugQuery } from '../../graphql-types'
import Markdown from '../components/Markdown'
import Page from '../components/layout/Page'
import { mobile } from '../components/mixins'
import { Hero, Spacer, Container } from '../components/modules'
import Link from '../components/modules/Link'
import { Color } from '../components/theme'
import { Heading3, BodyRegular } from '../components/typography'

export const query = graphql`
  query JobBySlug($slug: String!) {
    job: contentfulJobListing(slug: { eq: $slug }, node_locale: { eq: "en" }) {
      ...Job
    }
  }
`

const Content = styled(Container)`
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  ${mobile(css`
    flex-direction: column;
  `)}
`

const Body = styled.div`
  flex: 2;
  margin-right: 10%;
`

const Side = styled.aside`
  flex: 1;
`

const Info = styled.div`
  padding: 24px 0;

  & > * + * {
    margin-top: 8px;
  }
`

const ApplyButton = styled(Link)`
  ${Body} & {
    margin-right: 24px;
  }

  ${Side} & {
    margin-bottom: 16px;
  }
`

const ApplyText = styled(BodyRegular)`
  color: ${Color.GRAY_6};

  ${Side} & {
    ${mobile(css`
      display: none;
    `)}
  }
`

const EmailLink = styled.a`
  white-space: nowrap;
`

const translationsByLanguage: Record<string, Record<string, string>> = {
  fr: {
    'What you will do': 'Description du poste',
    'Who you are': 'Profil recherché',
    'Benefits & perks': 'Avantages',
    Apply: 'Postuler',
    'If you have any questions, please e-mail us at ':
      'Des questions ? Envoyez nous un e-mail à ',
    'Salary: ': 'Salaire : ',
    'Wages: ': 'Salaire : ',
    Location: 'Localisation',
  },
}

function translator(language: 'fr' | 'en') {
  const translations = translationsByLanguage[language] || {}

  return function (strings: TemplateStringsArray, ...expressions: any[]) {
    // Translate strings
    const strs = strings.map((string) => translations[string] || string)
    // Re-build complete string
    const parts = [strs[0]]
    expressions.forEach((expression, index) =>
      parts.push(expression, strs[index + 1]),
    )
    return parts.join('')
  }
}

type Props = {
  data: JobBySlugQuery
}

const Job = ({ data }: Props) => {
  const job = data?.job

  const _ = translator(job?.isFrench ? 'fr' : 'en')
  const emailAddress = job?.isFrench
    ? 'jobs-fr@withdouble.com'
    : 'jobs@withdouble.com'

  return (
    <Page
      head={{
        title: `Jobs at Double - ${job?.title}`,
        description: job?.body?.body?.substr(0, 100),
      }}
    >
      <Hero title={job?.title} />
      <Spacer size={'Medium'} />

      <Content>
        <Body>
          <Markdown markdown={job?.body?.body || ''} />
          <ApplyText noColor>
            <ApplyButton
              button
              isCompact
              label={_`Apply`}
              url={job?.applyLink}
              target={'apply'}
              id={'jobbottom-apply'}
            />
            {_`or email `}
            <EmailLink href={`mailto:${emailAddress}`}>
              {emailAddress}
            </EmailLink>
          </ApplyText>
        </Body>

        <Side>
          <Info>
            <Heading3>{_`Compensation`}</Heading3>
            {job?.isHq ? (
              <BodyRegular>{_`Salary: ` + `${job?.salary}`}</BodyRegular>
            ) : (
              <BodyRegular>{_`Wages: ` + `${job?.salary}`}</BodyRegular>
            )}
          </Info>
          <Info>
            <Heading3>{_`Location`}</Heading3>
            <BodyRegular>
              {job?.locations?.map((location) => (
                <span key={location || ''}>
                  {location}
                  <br />
                </span>
              ))}
            </BodyRegular>
          </Info>
          <ApplyText noColor>
            <ApplyButton
              button
              isCompact
              label={_`Apply`}
              url={job?.applyLink}
              target={'apply'}
              id={'jobsidebar-apply'}
            />
            <br />
            {_`If you have any questions, please e-mail us at `}
            <EmailLink href={`mailto:${emailAddress}`}>
              {emailAddress}
            </EmailLink>
          </ApplyText>
        </Side>
      </Content>

      <Spacer size={'Large'} />
    </Page>
  )
}

export default Job
